@font-face {
font-family: '__kintoSans_7637b6';
src: url(/_next/static/media/c69578de401dfb6a.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__kintoSans_7637b6';
src: url(/_next/static/media/198e6f83e58f6077.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__kintoSans_7637b6';
src: url(/_next/static/media/e2bc1bb3cc53f561.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__kintoSans_7637b6';
src: url(/_next/static/media/bcc31903dd428af8.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}@font-face {font-family: '__kintoSans_Fallback_7637b6';src: local("Arial");ascent-override: 83.64%;descent-override: 11.40%;line-gap-override: 0.00%;size-adjust: 105.22%
}.__className_7637b6 {font-family: '__kintoSans_7637b6', '__kintoSans_Fallback_7637b6'
}

